
import { GspModuleState } from './types';

export const state = (): GspModuleState => initialState();

export const initialState = (): GspModuleState => ({
  gspModules: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name'],
      andClauseFieldsIds: [],
    },
  },
});
