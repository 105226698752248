import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import contact, { Contact, Value } from '../model/contact';
import ODataFilterBuilder from 'odata-filter-builder';
import { CompanyZrResult } from '../model/company';

const logger = new Logger('backend.Contact');
export interface BackendContact {
  getContact(id: string): AxiosPromise<Contact>;
  getContactByEmail(email: string): AxiosPromise<Contact>;
  getContacts: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getCompanyZr(payload: any): AxiosPromise<CompanyZrResult[]>;
  getMasterZrGsp(contactZrs: any): AxiosPromise<any>;  
  deleteContact(id: string): AxiosPromise;
  updateContact(Contact: Contact): AxiosPromise<any>;
  updateContactRights(Contact: any): AxiosPromise<any>;
  updateContactFreigabeZrPortal(data: any): AxiosPromise<any>;
  updateContactAdditionalInfo(Contact: Contact): AxiosPromise<any>;
  deactivateContact(contactDto: any): AxiosPromise<any>;
  countHouseAdminsInZrCluster(Contact: Contact): AxiosPromise<number>;
  countActiveServices(contactId: number, contactType: number): AxiosPromise<number>;
  getContactsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
}

export const defaultBackendContact: BackendContact = {
  getContact(id: string): AxiosPromise<Contact> {
    const url = `${URLS.contactOdata}/${id}`;
    logger.debug(url);
    return instance.get<Contact>(url);
  },
  getContactByEmail(id: string): AxiosPromise<Contact> {
    const url = `${URLS.contact}/getContactByEmail/${id}`;
    return instance.get<Contact>(url);
  },
  getCompanyZr(data: any): AxiosPromise<CompanyZrResult[]> {
    //return instance.get<CompanyZrResult[]>(`${URLS.contact}/GetCompanyZr/${isAllCompanies}/${contactType}`);
    return instance.put<CompanyZrResult[]>(`${URLS.contact}/GetCompanyZr`, data);
    //return instance.get<CompanyZrResult[]>(url);
  },  
  getMasterZrGsp(contactZrs: any): AxiosPromise<any> {
    // TODO (AD-103): maybe ignore this request if user is ADMIN
    const url = `${URLS.contact}/GetMasterZrGsp`;
    return instance.put<Contact>(url, contactZrs);
  },
  getContacts(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    const twoWordsSearcParamFilter = searchParams.filter?.includes(' ');
    if (twoWordsSearcParamFilter) {
      let filterWords = searchParams.filter.split(' ');
      const odfbOr1stCondition = ODataFilterBuilder('or');
      const odfbOr2ndCondition = ODataFilterBuilder('or');

      odfbOr1stCondition.contains((x: any) => x.toLower('firstName'),filterWords[0].toLowerCase()).contains((x: any) => x.toLower('lastName'),filterWords[0].toLowerCase());
      odfbOr2ndCondition.contains((x: any) => x.toLower('firstName'),filterWords[1].toLowerCase()).contains((x: any) => x.toLower('lastName'),filterWords[1].toLowerCase());

      odfb.and(odfbOr1stCondition).and(odfbOr2ndCondition);
    }

    const url = DefaultBackendHelper.makeUrl(
      `${URLS.contactOdata}`,
      searchParams.dataOption,
      twoWordsSearcParamFilter ? [] : searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getContacts${url}`);
    return instance.put<Value>(url, searchData);
  },

  deleteContact(id: string): AxiosPromise {
    logger.debug('deleteContact');
    return instance.delete(`${URLS.contact}/${id}`);
  },
  updateContact(Contact: Contact): AxiosPromise<any> {
    logger.debug('updateContact');
    return instance.put<Contact>(`${URLS.contact}/update`, contact.toAPI(Contact));
  },
  updateContactRights: function (ContactRights: any): AxiosPromise<any> {
    logger.debug('updateContactRights');
    return instance.put<Contact>(`${URLS.contact}/updateContactRights`, ContactRights);
  },
  updateContactFreigabeZrPortal(data: any): AxiosPromise<any> {
    logger.debug('updateContactFreigabeZrPortal');
    return instance.put<Contact>(`${URLS.contact}/updateContactFreigabeZrPortal`, data);
  },
  updateContactAdditionalInfo(Contact: Contact): AxiosPromise<any> {
    return instance.put<Contact>(`${URLS.contact}/updateAdditionalInfo`, contact.additionalInfoToAPI(Contact));
  },
  deactivateContact: (contactDto: any): AxiosPromise<any> => {
    logger.debug('deactivateContact');
    return instance.put<Contact>(`${URLS.contact}/deactivateContact`, contactDto);
  },
  countHouseAdminsInZrCluster(Contact: Contact): AxiosPromise<number> {
    return instance.get<number>(`${URLS.contact}/CountHouseAdminsInZrCluster/${Contact.email}`);
  },
  countActiveServices(contactId: number, contactType: number): AxiosPromise<number> {
    return instance.get<number>(`${URLS.contact}/CountActiveServices/${contactId}/${contactType}`);
  },

  getContactsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    let url = `${URLS.contact}/GetContactsExcelReport`;
    data.searchWord = searchParams.filter;

    logger.debug(`GetContactsExcelReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
};
