
import { ActionTree } from 'vuex';
import { TblUpdateDocumentState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateDocument } from '@/shared/backend/tblUpdateDocument';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateDocument, { Value, TblUpdateDocument } from '@/shared/model/tblUpdateDocument';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateDocument');
export const actions: ActionTree<TblUpdateDocumentState, RootState> = {
  getTblUpdateDocuments({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, documentSearchData?: any  }) {
    commit('setTblUpdateDocumentsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateDocumentsSearchParams;
    let documentSearchData = payload?.documentSearchData || {};
    return defaultBackendTblUpdateDocument
      .getTblUpdateDocuments(searchParams, documentSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateDocuments', response.data);
        commit('setTblUpdateDocumentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblUpdateDocumentsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCountWerbungDocument({ commit, dispatch },  payload?: { searchParams?: any, documentSearchData?: any  }) {
    return defaultBackendTblUpdateDocument
      .getCountWerbungDocument(payload?.searchParams, payload?.documentSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateDocumentsWerbung', response.data);
        commit('setTblUpdateDocumentsWerbungIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddDocumentCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateDocument
      .getAddDocumentCompleted(payload)
      .then(
        (
            response: AxiosResponse<{
              result: TblUpdateDocument;
            }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselDocumentCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateDocument
      .getCanselDocumentCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateDocument;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTblUpdateDocument({ commit, dispatch }, id: string) {
    return defaultBackendTblUpdateDocument
      .deleteTblUpdateDocument(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TblUpdateDocument;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },
  updateDashboardDocumentsStatus({ commit, dispatch }, date: any) {
    commit('setDashboardDocumentsStatus', date);
  },
};
