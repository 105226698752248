
import { GspServiceState } from './types';

export const state = (): GspServiceState => initialState();

export const initialState = (): GspServiceState => ({
  gspServicesForContacts: undefined,
  gspServicesForContactsIsLoading: undefined,
  gspServices: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 200,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name'],
      andClauseFieldsIds: [],
    },
  },
});
