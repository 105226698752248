import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspUserRole, { GspUserRole, UserActiveServices, Value } from '../model/gspUserRole';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspUserRole');
export interface BackendGspUserRole {
  getGspUserRole(id: string): AxiosPromise<GspUserRole>;
  getGspUserRoles: (searchParams: SearchParams, data: any) => AxiosPromise<Value>;
  getGspUserRolesByContactId: (contactId: string) => AxiosPromise<Value>;
  deleteGspUserRole(id: string): AxiosPromise;
  updateGspUserRole(GspUserRole: GspUserRole): AxiosPromise<any>;
  getUserActiveServices:(contactId: any) => AxiosPromise<UserActiveServices[]>;
}

export const defaultBackendGspUserRole: BackendGspUserRole = {
  getGspUserRole(id: string): AxiosPromise<GspUserRole> {
    let url = `${URLS.gspUserRoleOdata}/${id}`;
    return instance.get<GspUserRole>(url);
  },
  getGspUserRoles(searchParams: SearchParams, data: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspUserRoleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=GspUserRoleDetails']
    );
    logger.log(`getGspUserRoles${url}`);
    return instance.put<Value>(url, data);
  },
  getGspUserRolesByContactId(contactId: string): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspUserRoleOdata}/GetByContactId(contactId=${contactId})`,
      undefined,
      undefined,
      undefined,
      undefined,
      ['$expand=GspUserRoleDetails']
    );
    logger.log(`getGspUserRoles${url}`);
    return instance.get<Value>(url);
  },
  getUserActiveServices(contactId: any) : AxiosPromise<UserActiveServices[]> {
    const url = `${URLS.gspUserRole}/GetUserActiveServices`;
    return instance.put<UserActiveServices[]>(url, contactId);
  },
  deleteGspUserRole(id: string): AxiosPromise {
    logger.debug('deleteGspUserRole');
    return instance.delete(`${URLS.gspUserRole}/${id}`);
  },
  updateGspUserRole(GspUserRole: GspUserRole): AxiosPromise<any> {
    logger.debug('updateGspUserRole');
    return instance.put<GspUserRole>(`${URLS.gspUserRole}/update`, gspUserRole.toAPI(GspUserRole));
  },
};
