import { MutationTree } from 'vuex';
import { GspUserRoleState } from './types';
import Vue from 'vue';
import gspUserRole, { GspUserRole, Value } from '@/shared/model/gspUserRole';
import { UserActiveServices } from '@/shared/model/gspUserRole';
import gspUserRoleDetail from '@/shared/model/gspUserRoleDetail';

export const mutations: MutationTree<GspUserRoleState> = {
  setGspUserRoles(state, payload: Value) {
    state.gspUserRoles.items = payload.value.map((x) => gspUserRole.parse(x));
    state.gspUserRoles.total = payload['@odata.count'] || 0;
    state.gspUserRoles.isLoading = false;
  },
  setGspUserRolesAllForAdmin(state, payload: Value) {
    state.gspUserRolesAllForAdmin.items = payload.value.map((x) => gspUserRole.parse(x));
    state.gspUserRolesAllForAdmin.total = payload['@odata.count'] || 0;
    state.gspUserRolesAllForAdmin.isLoading = false;
  },
  setGspUserRolesActiveAndNot(state, payload: Value) {
    state.gspUserRolesActiveAndNot.items = payload.value.map((x) => gspUserRole.parse(x));
    state.gspUserRolesActiveAndNot.total = payload['@odata.count'] || 0;
    state.gspUserRolesActiveAndNot.isLoading = false;
  },
  setGspUserRolesTotal(state, payload: number) {
    state.gspUserRoles.total = payload;
  },
  setGspUserRolesIsLoading(state, payload: boolean) {
    state.gspUserRoles.isLoading = payload;
  },
  replaceGspUserRolesByContactId(state, payload: { gspUserRoles: Value; contactId: string }) {
    let parsedGspUserRoles = payload.gspUserRoles.value.map((x) => gspUserRole.parse(x));
    state.gspUserRoles.items = state.gspUserRoles.items.filter((x) => x.contactId != payload.contactId);
    state.gspUserRoles.items = state.gspUserRoles.items.concat(parsedGspUserRoles);
  },
  addRoleFromBackend(state, payload: any) {
    console.log('payload :>> ', payload);
    state.gspUserRoles.items.push(gspUserRole.parse(payload));
  },
  addRoleDetailFromBackend(state, payload: any) {
    const parsedUserRoleDetails = gspUserRoleDetail.parse(payload);
    let userRole = state.gspUserRoles.items.find((x) => x.id == parsedUserRoleDetails.gspUserRoleId);
    if (userRole) {
      let userRoleDetailIndex = userRole!.gspUserRoleDetails.findIndex((x: any) => x.serviceId == payload.serviceId);
      if (userRoleDetailIndex < 0) {
        userRole!.gspUserRoleDetails.push(parsedUserRoleDetails);
      } else {
        userRole!.gspUserRoleDetails.splice(userRoleDetailIndex, 1, parsedUserRoleDetails);
      }
    }
  },
  setUserActiveServices(state, payload: any) {
    if (payload.contactId) {
      let gspUserActiveService = state.gspUserActiveServicesList.find((x: any) => x.contactID == payload.contactId);
      if (gspUserActiveService) {
        gspUserActiveService.countRight = payload.activeCount;
      } 
    } else {
      state.gspUserActiveServicesList = payload;
    }
  },
};
