import { ActionTree } from 'vuex';
import { TblKollegenauslieferungState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblKollegenauslieferung } from '@/shared/backend/tblKollegenauslieferung';
import { KollegenauslieferungFacetSearchData,  KollegenauslieferungSearchData } from '@/shared/model/smallPayloadModels/kollegenauslieferungSearchData';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblKollegenauslieferung, { Value, TblKollegenauslieferung } from '@/shared/model/tblKollegenauslieferung';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

export const actions: ActionTree<TblKollegenauslieferungState, RootState> = {
  getKollegenauslieferung(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; kollegenauslieferungSearchData?: any }
  ) {
    commit('setTblKollegenauslieferungsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getKollegenauslieferungsSearchParams;
    let searchData = payload?.kollegenauslieferungSearchData ?? {};
    return defaultBackendTblKollegenauslieferung
      .getKollegenauslieferung(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblKollegenauslieferungs', response.data);
        commit('setTblKollegenauslieferungsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblKollegenauslieferungsIsLoading', false);
        throw e;
      });
  },
  getZrNumberList({ commit, dispatch }) {
    return defaultBackendTblKollegenauslieferung
      .getZrNumberList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getVMEHauptwarengruppenList({ commit, dispatch }) {
    return defaultBackendTblKollegenauslieferung
      .getVMEHauptwarengruppenList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {      
        throw e;
      });
  },
  getKollegenauslieferungFacetSearch({ commit, dispatch }, payload: KollegenauslieferungFacetSearchData) {
    return defaultBackendTblKollegenauslieferung
      .getKollegenauslieferungFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {        
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {        
        throw e;
      });
  },
  updateKollegenauslieferungSearchData({ commit, dispatch }, data: any) {
    commit('setKollegenauslieferungSearchData', data);
  },

};
