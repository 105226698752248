
import { SearchParamsEmpty } from '@/shared/model/searchParams';
import { TblUpdateNewsState } from './types';

export const state = (): TblUpdateNewsState => initialState();

export const initialState = (): TblUpdateNewsState => ({
  dashboardDate: undefined,
  tblUpdateNews: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['nw_createdate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['nw_createdate', 'nw_title', 'nw_description', 'nw_sitetitle', 'nw_category', 'nw_author', 'newsDoneTimeCompleted', 'newsUserNameCompleted'],
      andClauseFieldsIds: [],
    },
  },  
});
