import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import kollegenauslieferungSearchData, {
  KollegenauslieferungFacetSearchData,
  KollegenauslieferungSearchData,
} from '@/shared/model/smallPayloadModels/kollegenauslieferungSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('search-form');
const authModule = namespace('auth');
const changeVoucherModule = namespace('changeVoucher');
const tblKollegenauslieferungModule = namespace('tblKollegenauslieferung');

@Component({
  components: { DateField },
})
export default class SearchFormKollegenauslieferung extends Vue {
  @Ref('deliverAbroadCombobx')
  private refDeliverAbroadCombobx!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('titlesCombobox')
  private refTitlesCombobox!: any;

  @tblKollegenauslieferungModule.Action('getZrNumberList')
  private actionGetZrNumberList!: any;

  @tblKollegenauslieferungModule.Action('getVMEHauptwarengruppenList')
  private actionGetVMEHauptwarengruppenList!: any;

  @tblKollegenauslieferungModule.Action('updateKollegenauslieferungSearchData')
  private actionUpdateKollegenauslieferungSearchData!: any;

  @tblKollegenauslieferungModule.Getter('getKollegenauslieferungsSearchParams')
  private getKollegenauslieferungsSearchParams!: any;

  @tblKollegenauslieferungModule.Getter('getKollegenauslieferungSearchData')
  private getKollegenauslieferungSearchData!: any;

  @tblKollegenauslieferungModule.Getter('isKollegenauslieferungSearchDataEmpty')
  private isKollegenauslieferungSearchDataEmpty!: any;

  listZrNumber = [];
  listVMEHauptwarengruppen = [];
  private selectedStatus: string = '';
  kollegenauslieferungSearchData = kollegenauslieferungSearchData.defaultData();

  created() {   
    if (!this.getKollegenauslieferungsSearchParams) {
      this.getKollegenauslieferungsSearchParams = kollegenauslieferungSearchData.defaultData(); // `{}` returned  error
    }
  }

  async mounted() {
    var promiseAll = [];
    promiseAll.push(this.getZrNumberList(), this.getVMEHauptwarengruppenList());
  }

  isSearchDataWasCleared = false;
  @Watch('isKollegenauslieferungSearchDataEmpty', { deep: true })
  public async onIsWochenabschluesseSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)
      this.kollegenauslieferungSearchData = kollegenauslieferungSearchData.defaultData();
    }
  }

  @Watch('kollegenauslieferungSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: KollegenauslieferungFacetSearchData, oldVal: KollegenauslieferungFacetSearchData) {
    // avoid loop when `searchData` was reset
    if (!this.isSearchDataWasCleared) {
      let payload = Object.assign({}, this.kollegenauslieferungSearchData);
      payload.receiverNumbers = this.kollegenauslieferungSearchData.receiverNumbers;
      payload.hauptwarengruppenVME = this.kollegenauslieferungSearchData.hauptwarengruppenVME;
      payload.deliver_abroad = this.kollegenauslieferungSearchData.deliver_abroad;
        payload.deliver_island = this.kollegenauslieferungSearchData.deliver_island;
        payload.delivery_colleague = this.kollegenauslieferungSearchData.delivery_colleague;
        this.actionUpdateKollegenauslieferungSearchData(payload);
    }
    this.isSearchDataWasCleared = false;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrWochenabschluesseSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }

  private loadingMembers = false;
  private async getZrNumberList() {
    this.actionGetZrNumberList()
      .then((result: any) => {
        this.listZrNumber = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getVMEHauptwarengruppenList() {
    this.actionGetVMEHauptwarengruppenList()
      .then((result: any) => {
        this.listVMEHauptwarengruppen = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  showMenu() {
    this.refDeliverAbroadCombobx.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;

  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchTitlesInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchTitlesInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  // clearSearchFormData() {
  //   this.actionResetwochenabschluesseSearchFormData();
  // }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
      twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday; // && isDateMoreThan2Weeks;
  }

  private isTodayMondayOrTuesday() {
    const today = new Date();
    const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
    return isMondayOrTuesday;
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_true`), //'Offen',
      value: 'true',
    },
    {
      text: this.$i18n.t(`select_false`), //'Erledigt',
      value: 'false',
    },
  ];

  changeSelectDeliverIsland() {
    //this.getTblUpdateConditions();
  }

  changeSelectDeliveryColleague() {
    //this.getTblUpdateConditions();
  }

  changeSelectDeliverAbroad() {
    if (this.kollegenauslieferungSearchData.deliver_abroad == null)
    {
      //this.kollegenauslieferungSearchData.deliver_abroad='all';
      //this.refDeliverAbroadCombobx.activateMenu();
    }
  }
}
