import { GetterTree } from 'vuex';
import { TblKollegenauslieferungState } from './types';
import { RootState } from '../../types';
import { KollegenauslieferungSearchData } from '@/shared/model/smallPayloadModels/kollegenauslieferungSearchData';

export const getters: GetterTree<TblKollegenauslieferungState, RootState> = {
  ['getKollegenauslieferungs']: (state) => state.tblKollegenauslieferungs,
  ['getTKollegenauslieferungsIsLoading']: (state) => state.tblKollegenauslieferungs?.isLoading,
  ['getKollegenauslieferungsTotal']: (state) => state.tblKollegenauslieferungs?.total,
  ['getKollegenauslieferungsSearchParams']: (state) => state.tblKollegenauslieferungs?.searchParams,
  ['getKollegenauslieferungSearchData']: (state) => state.kollegenauslieferungSearchData,

  ['isKollegenauslieferungSearchDataEmpty']: (state) => {
    const searchData: KollegenauslieferungSearchData = state.kollegenauslieferungSearchData;
    let hasZrNummer = searchData.receiverNumbers?.length > 0;
    let hasHauptwarengruppenVME = searchData.hauptwarengruppenVME?.length > 0;
    let hasDeliverAbroad = searchData.deliver_abroad != null && searchData.deliver_abroad != 'all';
    let hasDeliverIsland = searchData.deliver_island != null && searchData.deliver_island != 'all';
    let hasDeliveryColleague = searchData.delivery_colleague != null && searchData.delivery_colleague != 'all';
    let isEmpty = !hasZrNummer && !hasDeliverAbroad && !hasDeliverIsland && !hasDeliveryColleague && !hasHauptwarengruppenVME;
    console.log(
      '---------------isKollegenauslieferungSearchDataEmpty---------------------------------------------  ===>>> isEmpty  ',
      isEmpty,
      '  searchData.receiverNumbers  ',
      searchData.receiverNumbers,
      '  earchData.deliver_abroad ',
      searchData.deliver_abroad,
      '   hasDeliverAbroad ',
      hasDeliverAbroad
    );
    return isEmpty;
  },
};
