import { GetterTree } from 'vuex';
import { TblUpdateNewsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateNewsState, RootState> = {
  ['getTblUpdateNews']: (state) => state.tblUpdateNews,  
  ['getTblUpdateNewsTotal']: (state) => state.tblUpdateNews?.total,
  ['getTblUpdateNewsSearchParams']: (state) => state.tblUpdateNews?.searchParams,
  ['getDashboardDate']: (state) => state.dashboardDate,
  ['getTblUpdateNewsIsLoading']: (state) => state.tblUpdateNews?.isLoading,
  
};
