export interface GspUserRoleDetail {
  id: number; // uint
  gspUserRoleId: number; // uint
  gspUserRole: any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.GspUserRoleDm;
  roleDetails: GspUserRoleDetailInner[] | string;
  created: string; // System.DateTime?
  creatorId: string; // string
  creatorEmail: string; // string
  serviceId: number;

  contactId: number; //
  contactEmail: string;

  contactAzureId: string;
  modulesEntraGroups: string;
  prevRoles: any;
  roleDiff: any;

  // send to backend field for Logging
  roleId: number; //
  roleName: string;
  actionType: string;
  moduleId: number; //
  isCommonModule: boolean | null;
  zrNummer: string;
}

export interface GspUserRoleDetailInner {
  moduleRoles?: GspUserRoleDetailModuleRole[];
  zrNummerAll?: string; // for records(GspServices) with single ZrNummer, like Piex,Katalogwand where 1 line with Roles
  zrNummer?: string; // for records(GspServices) with multiple ZrNummer, like for ZR-Portal (7 rows or ZR-Nummers and 3 modules)
}

export interface GspUserRoleDetailModuleRole {
  id?: number;
  name?: string;
  roles?: number[];
  isCommon?: boolean;
}

export interface Value {
  value: GspUserRoleDetail[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspUserRoleDetail>): any {
  return {
    Id: data?.id || 0,
    GspUserRoleId: data?.gspUserRoleId || 0,
    GspUserRole: data?.gspUserRole || undefined,
    RoleDetails: JSON.stringify(data?.roleDetails || []),
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
    ServiceId: data?.serviceId || undefined,
    ContactId: data?.contactId || 0,
    ContactEmail: data?.contactEmail || '',

    contactAzureId: data?.contactAzureId || undefined,
    modulesEntraGroups: data?.modulesEntraGroups || undefined,
    prevRoles: data?.prevRoles || undefined,
    roleDiff: data?.roleDiff || undefined,

    RoleId: data?.roleId || 0,
    RoleName: data?.roleName || '',
    ActionType: data?.actionType || '',
    ModuleId: data?.moduleId || 0,
    IsCommonModule: data?.isCommonModule ? data?.isCommonModule : data?.isCommonModule === false ? false : null,
    ZrNummer: data?.zrNummer ?  data?.zrNummer + '' : undefined, //cast number to `string` as in database
  };
}

function parse(data?: Partial<GspUserRoleDetail>): GspUserRoleDetail {
  return {
    id: data?.id || 0,
    gspUserRoleId: data?.gspUserRoleId || 0,
    gspUserRole: data?.gspUserRole || '',
    roleDetails: JSON.parse((data?.roleDetails as string) || '[]'),
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    serviceId: data?.serviceId || 0,
    contactId: data?.contactId || 0,
    contactEmail: data?.contactEmail || '',


    contactAzureId: data?.contactAzureId || '',
    modulesEntraGroups: data?.modulesEntraGroups || '',
    prevRoles: data?.prevRoles || '',
    roleDiff: data?.roleDiff || '',


    roleId: data?.roleId || 0,
    roleName: data?.roleName || '',
    actionType: data?.actionType || '',
    moduleId: data?.moduleId || 0,
    isCommonModule: data?.isCommonModule || null,
    zrNummer: data?.zrNummer || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
