
import { CompanyState } from './types';

export const state = (): CompanyState => initialState();

export const initialState = (): CompanyState => ({
  companyUserZrs: undefined,
  companys: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['ActionEmailMessage'],
      andClauseFieldsIds: [],
    },
  },
});
