import { MutationTree } from 'vuex';
import { TblUpdateNewsState } from './types';
import Vue from 'vue';
import tblUpdateNews, { TblUpdateNews, Value } from '@/shared/model/tblUpdateNews';

export const mutations: MutationTree<TblUpdateNewsState> = {
  setTblUpdateNews(state, payload: Value) {
    state.tblUpdateNews.items = payload.value.map((x) => tblUpdateNews.parse(x));
    state.tblUpdateNews.total = payload['@odata.count'] || 0;
    state.tblUpdateNews.isLoading = false;
  },
  setTblUpdateNewsTotal(state, payload: number) {
    state.tblUpdateNews.total = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setUpdateNewsIsLoading(state, payload: boolean) {
    state.tblUpdateNews.isLoading = payload;
  },
};
