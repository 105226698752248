import { MutationTree } from 'vuex';
import { ContactState } from './types';
import Vue from 'vue';
import contact, { Contact, Value } from '@/shared/model/contact';

export const mutations: MutationTree<ContactState> = {
  setContacts(state, payload: Value) {
    state.contacts.items = payload.value.map((x) => contact.parse(x));
    state.contacts.total = payload['@odata.count'] || 0;
    state.contacts.isLoading = false;
  },
  replaceContact(state, updatedContact: Contact) {
    let parsedContact = contact.parse(updatedContact);
    let existingContact = state.contacts.items.find((x) => x.recordID == parsedContact.recordID);
    if (existingContact != undefined) {
      Object.assign(existingContact, parsedContact);
    }
  },
  setContactsTotal(state, payload: number) {
    state.contacts.total = payload;
  },
  setContactsIsLoading(state, payload: boolean) {
    state.contacts.isLoading = payload;
  },
  
  setCompanyZrs(state, payload: any) {
    state.companyZrs.items = payload;
    state.companyZrs.total = payload.length;
    state.companyZrs.isLoading = false;
  },
  setCompanyZrIsLoading(state, payload: boolean) {
    state.companyZrs.isLoading = payload;
  },
  setSearchData(state, payload: any) {
    state.searchData = payload;
  },
  setSearchDataIsActive(state, payload: any) {
    state.searchData.isActive = true;
    state.searchData.isInitialRequest = false;
  },
  setLoggedInContact(state, payload: any) {
    state.loggedInContact = payload;
  },
  setUserCountActiveServices(state, payload: any) {
    state.userCountActiveServices = payload;
  },
};
