import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateCondition, { TblUpdateCondition, Value } from '../model/tblUpdateCondition';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TblUpdateSupplier');
export interface BackendTblUpdateCondition {
  getTblUpdateSupplier(id: string): AxiosPromise<TblUpdateCondition>;
  getTblUpdateConditions: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getAddConditionCompleted(payload: any): AxiosPromise<any>;
  getTblUpdateConditionsCompleted: (TblUpdateCondition: TblUpdateCondition) => AxiosPromise<any>;
  deleteTblUpdateCondition(id: string): AxiosPromise;  
  getConditionsVisible(user_email: string): AxiosPromise<boolean>;
  getCanselConditionCompleted(payload: any): AxiosPromise<any>;
}

export const defaultBackendTblUpdateCondition: BackendTblUpdateCondition = {
  getTblUpdateSupplier(id: string): AxiosPromise<TblUpdateCondition> {
    let url = `${URLS.tblUpdateSupplierOdata}/${id}`;
    return instance.get<TblUpdateCondition>(url);
  },
  getTblUpdateConditions(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateConditionOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );    
    return instance.put<Value>(url, searchData);
  },
  deleteTblUpdateCondition(id: string): AxiosPromise {    
    return instance.delete(`${URLS.tblUpdateCondition}/${id}`);
  },
  getTblUpdateConditionsCompleted(TblUpdateCondition: TblUpdateCondition): AxiosPromise<any> {
    return instance.put<TblUpdateCondition>(
      `${URLS.tblUpdateCondition}/add`,
      tblUpdateCondition.toAPI(TblUpdateCondition)
    );
  },
  getAddConditionCompleted(data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateCondition}/AddConditionCompleted`, data);
  },
  getCanselConditionCompleted(data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateCondition}/CanselConditionCompleted`, data);
  },
  getConditionsVisible(user_email: string): AxiosPromise<boolean> {
    return instance.get<boolean>(`${URLS.tblUpdateCondition}/GetConditionsVisible/${user_email}`);
  },
};
