export interface TblKollegenauslieferung {
  id: number; // uint
  zr_number: string; // string
  gs_name: string; // string
  zip_circle: string; // string
  deliver_abroad: string; // bool
  deliver_abroad_country: string; // string
  deliver_island: string; // bool
  deliver_island_name: string; // string
  delivery_colleague: string; // bool
  delivery_colleague_contact: string; // string
  delivery_colleague_email: string; // string
  delivery_colleague_phone: string; // string
  delivery_colleague_productgroup: string; // string
  delivery_colleague_condition: string; // string
}

export interface Value {
  value: TblKollegenauslieferung[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblKollegenauslieferung>): any {
  return {
    id: data?.id || 0,
    zr_number: data?.zr_number || '',
    gs_name: data?.gs_name || '',
    zip_circle: data?.zip_circle || '',
    deliver_abroad: data?.deliver_abroad || '',
    deliver_abroad_country: data?.deliver_abroad_country || '',
    deliver_island: data?.deliver_island || '',
    deliver_island_name: data?.deliver_island_name || '',
    delivery_colleague: data?.delivery_colleague || '',
    delivery_colleague_contact: data?.delivery_colleague_contact || '',
    delivery_colleague_email: data?.delivery_colleague_email || '',
    delivery_colleague_phone: data?.delivery_colleague_phone || '',
    delivery_colleague_productgroup: data?.delivery_colleague_productgroup || '',
    delivery_colleague_condition: data?.delivery_colleague_condition || '',
  };
}

function parse(data?: Partial<TblKollegenauslieferung>): TblKollegenauslieferung {
  return {
    id: data?.id || 0,
    zr_number: data?.zr_number || '',
    gs_name: data?.gs_name || '',
    zip_circle: data?.zip_circle || '',
    deliver_abroad: data?.deliver_abroad || '',
    deliver_abroad_country: data?.deliver_abroad_country || '',
    deliver_island: data?.deliver_island || '',
    deliver_island_name: data?.deliver_island_name || '',
    delivery_colleague: data?.delivery_colleague || '',
    delivery_colleague_contact: data?.delivery_colleague_contact || '',
    delivery_colleague_email: data?.delivery_colleague_email || '',
    delivery_colleague_phone: data?.delivery_colleague_phone || '',
    delivery_colleague_productgroup: data?.delivery_colleague_productgroup || '',
    delivery_colleague_condition: data?.delivery_colleague_condition || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
