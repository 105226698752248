
import { GspUserRoleDetailState } from './types';

export const state = (): GspUserRoleDetailState => initialState();

export const initialState = (): GspUserRoleDetailState => ({
  gspUserRoleDetails: {
    items: [],
    isLoading: false,
    total: 2, 
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 500,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [''],
      andClauseFieldsIds: [],
    },
  },
});
