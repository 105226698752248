
import { TblUpdateConditionState } from './types';

export const state = (): TblUpdateConditionState => initialState();

export const initialState = (): TblUpdateConditionState => ({
  dashboardDate: undefined,
  dashboarCompanyWrGruppen: undefined,
  dashboardConditionsStatus:undefined,
  conditionsVisible: false,
  tblUpdateConditions: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['cnd_changedate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
      andClauseFieldsIds: [],
    },
  },
});
