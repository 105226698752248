
import { MutationTree } from 'vuex';
import { TblKollegenauslieferungState } from './types';
import Vue from 'vue';
import tblKollegenauslieferung, { TblKollegenauslieferung, Value } from '@/shared/model/tblKollegenauslieferung';

export const mutations: MutationTree<TblKollegenauslieferungState> = {
  setTblKollegenauslieferungs(state, payload: Value) {
    state.tblKollegenauslieferungs.items = payload.value.map((x) => tblKollegenauslieferung.parse(x));
    state.tblKollegenauslieferungs.total = payload['@odata.count'] || 0;
    state.tblKollegenauslieferungs.isLoading = false;
  },
  setTblKollegenauslieferungsTotal(state, payload: number) {
    state.tblKollegenauslieferungs.total = payload;
  },
  setTblKollegenauslieferungsIsLoading(state, payload: boolean) {
    state.tblKollegenauslieferungs.isLoading = payload;
  }, 
  setKollegenauslieferungSearchData(state, payload: any) {
    state.kollegenauslieferungSearchData = payload;
  },
};
