import { ActionTree } from 'vuex';
import { GspUserRoleState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspUserRole } from '@/shared/backend/gspUserRole';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspUserRole, {UserActiveServices, Value, GspUserRole } from '@/shared/model/gspUserRole';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { OdataItems } from '@/shared/model/OdataItems';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.gspUserRoles');
export const actions: ActionTree<GspUserRoleState, RootState> = {
  getGspUserRoles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, isGetAll?:boolean, isInitialRequest?: boolean | null }) {
    commit('setGspUserRolesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspUserRolesSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendGspUserRole
      .getGspUserRoles(searchParams, payload)
      .then((response: AxiosResponse<Value>) => {

        // (AD-139) for Admin store ALL `gspUserRoles` in separate state field to always search on ALL `gspUserRoles` not only for loaded contacts
        if (!!payload?.isGetAll) {
          commit('setGspUserRolesAllForAdmin', response.data);
        } else {
          if (payload?.isInitialRequest === true) {
            commit('setGspUserRolesActiveAndNot', response.data);
            commit('setGspUserRoles', response.data); // (AD-139) populate `GspUserRoles` to see `rights count` (Zugriffsrechte) af first request, otherwise everything is 0
          } else {
            commit('setGspUserRoles', response.data);
          }
        }
        commit('setGspUserRolesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspUserRolesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  reloadGspUserRolesByContactId({ commit, getters }, contactId: string) {
    // First check if the updated contact is relevant to this user, if not, save the api request.
    let allUserRoles = <OdataItems<GspUserRole>>getters.getGspUserRoles;
    if (allUserRoles.items.find((x) => x.contactId == contactId) == undefined) {
      return Promise.resolve();
    }

    return defaultBackendGspUserRole.getGspUserRolesByContactId(contactId).then((response: AxiosResponse<Value>) => {
      commit('replaceGspUserRolesByContactId', { gspUserRoles: response.data, contactId: contactId });
    });
  },
  getGspUserRole({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRole
      .getGspUserRole(id)
      .then((response: AxiosResponse<GspUserRole>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getUserActiveServices({ commit, dispatch }, contactId: any) {
    return defaultBackendGspUserRole
      .getUserActiveServices(contactId)
      .then((response: AxiosResponse<UserActiveServices[]>) => {
        commit('setUserActiveServices', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspUserRole({ commit, dispatch }, file: GspUserRole) {
    return defaultBackendGspUserRole
      .updateGspUserRole(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRole;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspUserRole_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspUserRole({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRole
      .deleteGspUserRole(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRole;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  addRoleFromBackend({ commit, dispatch }, data: any) {
    commit('addRoleFromBackend', data);
  },
  addRoleDetailFromBackend({ commit, dispatch }, data: any) {
    console.log('addRoleDetailFromBackend :>> ', data);
    commit('addRoleDetailFromBackend', data);
  },
};
