import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import UserMini from '@/components/user/mini/user-mini.vue';
import dataOptions, { DataOptions } from '@/shared/model/DataOptions';
// import { User } from '@/shared/model/user';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import TreeNavigator from '@/components/tree-navigator/tree-navigator.vue';
import searchFormKollegenauslieferung from '@/views/kollegenauslieferung/search-form-kollegenauslieferung/search-form-kollegenauslieferung.vue';
import { TranslateResult } from 'vue-i18n';
// import GeneralUtils from '@/shared/utils/generalUtils';
const logger = new Logger('components.position-list');
const dateUtils = DateUtils;

@Component({ name: 'd4y-table', components: { TreeNavigator, searchFormKollegenauslieferung } })
export default class D4yTable extends Vue {
  @Prop()
  private items!: any[];

  @Prop()
  private SwitchView!: string;

  @Prop({ default: false })
  private isNameAsRef!: boolean;
  @Prop({ default: false })
  private isTitleAsRef!: boolean;
  @Prop({ default: false })
  private isFirstNameAsRef!: boolean;
  @Prop({ default: false })
  private isTextAsRef!: boolean;
  @Prop({ default: () => { return  {menuItems: [], facetSearch: false, menuDisabled: false, hasResults: true, chipData: []} } })
  searchMenuObj!: any;

  @PropSync('options', {
    default: (): DataOptions => {
      return dataOptions.getDefault;
    },
  })
  private optionsDto!: DataOptions;

  @Prop({ default: () => [''] })
  private filterFields!: string[];

  // TODO:(EGRUP-79) same @Prop here and in the `tree-navigator` component probably better use Vuex state
  @Prop()
  private image?: {
    src: string;
    header: string;
    height: number;
    width: number;
  };

  @Prop()
  private selection?: {
    showSelect: boolean;
    singleSelect: boolean;
    customHeader: boolean;
  };

  // TODO:(EGRUP-105) same @Prop here and in the `tree-navigator` component probably better use Vuex state or another approach
  @Prop({ default: true })
  private expanded!: boolean;
  @Prop({ default: false })
  private tableHasFilterBtn!: boolean;
  @Prop({ default: false })
  private hasFooterBtn!: boolean;
  @Prop({ default: false })
  private isFooterBtnDisabled!: boolean;
  @Prop({ default: true })
  private isShowSearchField!: boolean;
  @Prop({ default: false })
  private preventMultiSort!: boolean;

  @Prop({ default: () => '' })
  private tableHeaderText!: string;
  @Prop({ default: () => 'search' })
  private searchLabel!: string;

  @Prop()
  private breadcrumbs!: any;
  @Prop({ default: false })
  private allowAdd!: boolean;
  @Prop({ default: false })
  private showDialog!: boolean;
  @Prop({ default: true })
  private isShowTableHeader!: boolean;
  @Prop({ default: true })
  private isShowTreeNavToolbar!: boolean;

  @Prop({ default: false })
  private loadingFacet!: boolean;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop()
  private serverItemsLength!: number;

  @Prop({ default: false })
  allowDelete!: boolean;

  @Prop({ default: false })
  allowEdit!: boolean;

  @Prop({ default: false })
  haveMoreActions!: boolean;

  @Prop({ default: false })
  private isSearchDataEmpty!: boolean;

  // @Watch('optionsDto.filter')
  // public onOptionsFilterChanged(newVal: any, oldVal: any) {
  //   logger.debug(`----filter:${oldVal}->${newVal}`);
  //   this.$emit('update:options', this.optionsDto);
  // }
  private searchTerm = '';
  private changeSearch(value: string) {
    if (value !== this.optionsDto.filter) {
      this.optionsDto.filter = value;
      this.$emit('update:options', this.optionsDto);
    }
  }

  private changeFacetSearch(value: string) {
    // (GSP-074) if `menuDisabled` is true this means that facetSearch was completed and new request is sent (possibly for other field), otherwise (`menuDisabled` == false) user trying to send another request with the same unprocessed result from previous request (not choosing anything)
    if (value.length >= 2 && (value !== this.optionsDto.filter || this.searchMenuObj.menuDisabled)) {     
      this.optionsDto.filter = value;
      this.optionsDto.page = 1; // (GSP-082) go to 1st page, when change search word
      this.$emit('update:options', this.optionsDto, 'search');
    }
  }
  private resetSearch() {
    this.optionsDto.filter = '';
    this.$emit('update:options', this.optionsDto);
  }

  private sortDesc?: string;
  clickSortAsc() {
    var sort: boolean[] = [];
    this.optionsDto.sortBy.forEach((fieldName, i) => {
      sort.push(false);
    });
    this.optionsDto.sortDesc = sort;
  }
  clickSortDesc() {
    var sort: boolean[] = [];
    this.optionsDto.sortBy.forEach((fieldName, i) => {
      sort.push(true);
    });
    this.optionsDto.sortDesc = sort;
  }
  clickSortEmpty() {
    this.optionsDto.sortDesc = [];
    this.optionsDto.sortBy = [];
  }

  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('optionsDto.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    logger.debug(`----sortBy:${oldVal}->${newVal}`);
    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      // Unfortunately there is no prop in v-data-table that does this, so needs to be done manually here.
      if (newVal.length > 1 && this.preventMultiSort) {
        this.optionsDto.sortBy = [this.optionsDto.sortBy.pop()];
        this.optionsDto.sortDesc = [(this.optionsDto.sortDesc as boolean[])?.pop() ?? this.optionsDto.sortDesc];
      }

      this.$emit('update:options', this.optionsDto);
    }
  }
  @Watch('optionsDto.sortDesc')
  public onOptionssortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    logger.debug(`-od-sortDesc:${oldValAsString}->${newValAsString}`);
    if (oldValAsString != newValAsString) {
      this.sortDesc = newValAsString;
      logger.debug('+');
      this.$emit('update:options', this.optionsDto);
    }
  }
  @Watch('optionsDto.page')
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    logger.debug(`----page:${oldVal}->${newVal}`);
    this.$emit('update:options', this.optionsDto);
  }

  get isSortByEmpty() {
    return this.optionsDto.sortBy.length < 1;
  }

  selectItem(item: any) {
    if (!item.value) delete item.item; // remove item if value not selected
    this.$emit('selected:item', item.item);
  }

  /**
   * Check if named slot used in the `d4y-table` implementation in parent components (like `groups`[groups_list in Sketch], etc)
   * @param name (name of the slot)
   */
  private hasSlot(name: string) {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  }

  @Prop({ default: () => [{ text: 'email', value: 'email' }] })
  private headers!: {
    text: string | any;
    value: string;
    sortable?: boolean;
    width?: string;
  }[];

  @Prop({ default: 'primary' })
  private color!: string;
  get searchListLabel() {
    // if (this.filterFields && this.filterFields.length > 0) label = `${this.$t('search', { 0: this.filterFields })}`;
    // logger.debug(`search label:${this.filterFields} -> ${label}`);
    return this.searchLabel;
  }

  formatDate(date: any) {
    var sdate = dateUtils.toDateString(date);
    logger.debug(sdate);
    return sdate;
  }
  created() {
    this.sortDesc = JSON.stringify(this.optionsDto.sortDesc);
  }

  get isKollegenauslieferungRoutes() {
    return this.$route.fullPath.includes('kollegenauslieferung');
  }

  get isFlexWrapWidth() {
    return this.$vuetify.breakpoint.width < 730;
  }

  private changeSearchInput(value: string) {
    if (value && value.length == 0 && this.optionsDto.filter && this.optionsDto.filter?.length > 0) {     
      this.optionsDto.filter = value;
      this.$emit('update:options', this.optionsDto, 'search');
    }
  }

  searchInRealObjects(item: any) {
    this.$emit('search:chip', item);
    if (item.field !== 'all') {
      this.searchTerm = '';
    }
  }

  removeChip(item: any) {   ;
    this.$emit('remove:chip', item);
  }

  private clearAllFilters() {    
    this.$emit('click:clear-all-filter');
  }
}
