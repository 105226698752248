import { SearchParamsEmpty } from '@/shared/model/searchParams';
import { ContactState } from './types';
import searchData from '@/shared/model/smallPayloadModels/searchData';

export const state = (): ContactState => initialState();

export const initialState = (): ContactState => ({
  searchData: searchData.defaultData(),
  loggedInContact: undefined,
  userCountActiveServices: 0,
  contacts: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 500,
        sortBy: ['lastName'],
        sortDesc: false,
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['firstName', 'lastName', 'email'],
      andClauseFieldsIds: [],
    },
  },
  companyZrs: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: SearchParamsEmpty
  }
});
